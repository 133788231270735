 /*Provide sufficient contrast against white background

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.align-center {
    text-align: center !important;
}

.navlink-align-center {
    display: inline-block !important;
}

.custom-nav-menu {
    background-color: seagreen;
    padding: 0 !important;
}

.nav-item-content {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.pad-custom{
    padding: 0 !important;
}
.active-custom {
    font-weight: bold !important;
    background-color: white !important;
    color: black !important;
}

.navbar-logo {
    max-height: 50px;
    max-width: 150px;
}

.btn-primary {
    color: #fff;
    background-color: limegreen;
    border-color: limegreen;
}

.btn-edit-user {
    color: white;
    background-color: blue;
    text-align: center;
    cursor: pointer;
    width: auto !important;
    border: 1px solid white !important;
}

.component-title{
    text-align: center;
}

.forgotPassword {
    text-decoration: underline;
    padding: 0;
}

.passwordResponse {
    color: limegreen;
    font-size: 14px;
    font-weight: bold;
    text-decoration:underline;
}

.form-label {
    font-weight: bold;
}

.mainDarkGray {
    background-color: whitesmoke;
    width: 100% !important;
    padding: 20px;
}

.container {
    max-width: 100% !important;
}

.lightGray {
    background-color: white;
    padding: 10px;
    border: solid lightgray;
    border-width: 1px;
    border-radius: 5px;
    width: 50vw;
}


.depots-container {
    margin-top: 30px;
    background-color: white;
    padding: 10px;
    border: solid lightgray;
    border-width: 1px;
    border-radius: 5px;
    width: 50vw;
}

.custom-list-group {
    border: none !important;
}

.custom-list-group .list-group-item {
    border: none !important;
}*/

.clickable-tr {
    cursor: pointer !important;
}

tr {
    cursor: pointer !important;
}

.selected-tr {
    background-color: dodgerblue !important;
}

.align-center {
    text-align: center !important;
}

.popup-overlay {
    overflow: scroll;
}

.table-container {
    overflow-x: auto;
}

.whs-data-report .col-md-2 {
    padding-left: 0;
    padding-bottom: 10px;
}

.received-sales-files-report .col-md-2 {
    padding-bottom: 10px;
}
/*always show ag grid scrolls*/
 .ag-body-viewport-wrapper.ag-layout-normal {
     overflow-x: scroll;
     overflow-y: scroll;
 }
 ::-webkit-scrollbar {
     -webkit-appearance: none;
     width: 8px;
     height: 8px;
 }
 ::-webkit-scrollbar-thumb {
     border-radius: 4px;
     background-color: rgba(0,0,0,.5);
     box-shadow: 0 0 1px rgba(255,255,255,.5);
 }
 /*end of always show ag grid scrolls*/

/*.btn-primary.disabled, .btn-primary:disabled {
    background-color: gray;
    border-color: gray;
}

.btn-sku-map {
    margin-top: 20px;
    top: 100%;
    position: absolute;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.popup-content {
    text-align: center;
    width: 90% !important;
}

.modal-delete-confirm  {
    display: inline-block !important;
}

.modal-delete-confirm .popup-content {
    width: auto !important;
}

.nav-item .dropdown, .dropdown-toggle, .dropleft {
    height: 100% !important;
    background-color: inherit !important;
    border: none !important;
}

.margintop-15 {
    margin-top: 15px !important;
}

.custom-depots-ul {
    overflow-x: auto !important;
    word-wrap: break-word;
    -moz-column-count: 4;
    -moz-column-gap: 20px;
    -webkit-column-count: 4;
    -webkit-column-gap: 20px;
    column-count: 4;
}
.custom-depots-ul li {
    display:table;
}

.box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.box-body {
    padding: 10px;
}

.react-daterange-picker__wrapper{
    border: none;
}

.whs-data-report .row {
    margin: 0 !important;
}

.whs-data-report .col-md-6 {
    margin-bottom: 1rem;
}

.received-sales-files-report .col-md-2 {
    margin-bottom: 1rem;
}

.generating-sales-files-report .col-sm-12 {
    margin-bottom: 0.75rem;
}

.btn-block {
    width: 100% !importan*/
.rce-citem-avatar {
    display: none!important;
}
.rce-citem-body {
    padding: 10px;
}

.loader {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #1976d2; /* Blue */
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 2s linear infinite;
}

 @keyframes spin {
     0% { transform: rotate(0deg); }
     100% { transform: rotate(360deg); }
 }


 /* Material ui fixes*/
.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl { padding-top: 0; padding-bottom: 0;}
.MuiAutocomplete-root .MuiInputLabel-outlined { transform: translate(10px, 16px) scale(1); font-size: 10px; }
.MuiAutocomplete-root .MuiInputLabel-outlined.Mui-focused { transform: translate(14px, -6px) scale(0.5); display: none; }
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink.MuiFormLabel-filled { display: none; }

.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiOutlinedInput-inputAdornedEnd { font-size: 10px; }


.MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth { display: block; }
.MuiAutocomplete-root .MuiOutlinedInput-notchedOutline { border-radius: 2px; top: 0; height: 38px; line-height: 1; border-color: #767676; border-color: #e2e2e2;   display: block; }
.MuiAutocomplete-root .MuiOutlinedInput-notchedOutline legend { height: auto !important; }
.MuiAutocomplete-root fieldset { margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-block-end: 0; }

/* arrow and delete btn */
.MuiAutocomplete-endAdornment { top: calc(50% - 12px); }

.MuiAutocomplete-root .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input { height: 38px; padding-top: 0; padding-bottom: 0; }
.MuiAutocomplete-root .MuiAutocomplete-inputRoot.MuiAutocomplete-input { height: 40px; padding-top: 0; padding-bottom: 0; font-size: 10px; }
.MuiAutocomplete-option { font-size: 10px !important; padding: 5px !important; }

.MuiAutocomplete-paper { font-size: 12px !important; line-height: 1.3 }

.MuiAutocomplete-root .MuiOutlinedInput-notchedOutline span { display: none; }



/* Modal fixes*/


.modal-dialog { max-width: 900px !important; }

.react-datepicker {  }
.react-datepicker-popper {
    z-index: 99;
}
.react-datepicker__tab-loop { position: absolute; top: 0; left: 0;}
.react-datepicker__input-container { padding: 0 10px; }
.react-datepicker__input-container > input { width: 100%; height: 38px; }

.datepickerWrap p { padding-left: 10px; padding-top: 10px; font-size: 18px; }
